import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const FileField = ({fieldClass, labelClass, type, name, value, required, isValidFileSize, filseSizeErr, placeholder, handlechange, accept, label, grpmd}) => (
  <Form.Group  controlId={"validation" + name}>
    { label
      ? <Form.Label className={labelClass}>{label}{required ? '*' : ''}</Form.Label>
      : ''
    }
    <div className={fieldClass}>
      <Form.Control
        required={required}
        type={type}
        name={name}
        value={value}
        onChange={handlechange}
        accept={accept}
        id="custom-file"
        className="d-none"
      />
      <label for="custom-file" className="custom-file-label">{placeholder}</label>
    </div>
    {!isValidFileSize && <p class="error-msg">{filseSizeErr}</p>}
  </Form.Group>
);

export default FileField;
import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./QuizQuestions.scss"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { Scrollbars } from "react-custom-scrollbars-2"
import QuizForm from "./QuizFormrender"
import $ from "jquery"
const QuizQuestions = props => {
  return (
    <React.Fragment>
      <section className="quiz-questions quiz-form" id="quiz">
        <Container>
          <Row className="d-md-flex">
            <Col lg={5} md={12}>
              <ScrollAnimation
                animateOnce={true}
                duration={0.5}
                animateIn="fadeInLeft"
              >
                <span className="small-heading">The rockstar Quiz</span>
                <h2>Have you got what it takes to be a</h2>
                <h3>rockstar?</h3>
              </ScrollAnimation>
            </Col>

            <Col lg={7} md={12}>
              <ScrollAnimation
                animateOnce={true}
                duration={0.5}
                animateIn="fadeInRight"
              >
                <QuizForm />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default QuizQuestions

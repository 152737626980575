import React, { useEffect, useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Row } from "react-bootstrap"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./PropertyManagementGoogleReview.scss"

import TimeAgo from "react-timeago"
import buildFormatter from "react-timeago/lib/formatters/buildFormatter"
import frenchStrings from "react-timeago/lib/language-strings/en"

import axios from "axios"
import Modal from "react-bootstrap/Modal"
import GetRating from "../../getrating"
import reviewsLogo from "../../../images/google-reviews.svg"
import wordsToNumbers from "words-to-numbers"

const PropertyManagementGoogleReview = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [reviewid, setReviewid] = useState(false)

  const handleShow = event => {
    setReviewid(event)
    setShow(true)
  }
  const formatter = buildFormatter(frenchStrings)

  const [active, setActive] = useState("More")

  const handleAccordionClick = () => {
    if (active === "More") {
      setActive("Less")
    } else {
      setActive("More")
    }
  }

  const [testimonials, setTestimonials] = useState([])
  //console.log("testimonials",testimonials)
  const [sliderplay, setSliderplay] = useState(true)
  const strapiconfig = {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig) // could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }

  let [mobile, setMobile] = useState(false)

  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url)

    const userAgent = navigator.userAgent.toLowerCase()
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    )

    let check = false
    ;(function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
    setMobile(check || isTablet)
  }, [])

  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1520 },
      items: 3,
    },

    desktop: {
      breakpoint: { max: 1519, min: 1200 },
      items: 3,
    },

    tablet: {
      breakpoint: { max: 1200, min: 768 },
      items: 2,
    },

    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }
 
  return (
    <React.Fragment>
      <section className="g-review g-review-listing">
        <Container>
          <Row>
            <>
              {/* <Col xl={12}>
                <span className="small-heading">Google Reviews</span>
              </Col>
              <Col xl={12}>
                <h2>
                  {props.ReviewTitle
                    ? props.ReviewTitle
                    : "Real reviews, from real people, just like you."}
                </h2>
              </Col> */}
              <ScrollAnimation
                animateOnce={true}
                duration={0.5}
                animateIn="fadeInUp"
                className="content-section"
              >
                <h2>
                  {props.ReviewTitle ? (
                    props.ReviewTitle
                  ) : (
                    <>
                      Tried & <span className="yellow-with-font">Trusted</span>
                    </>
                  )}
                </h2>
                <div className="reviews-text-section">
                  <img src={reviewsLogo} alt="Reviews-Location Location" />
                  {testimonials && testimonials.length > 0 && 
                      <p className="text">
                        <span>
                        Rated{" "}
                        <strong> {parseFloat(rating_avg).toFixed(1)}/5 </strong>{" "}
                        from <strong>{testimonial_count} Reviews</strong>
                      </span>
                    </p>
                  }
                 
                </div>
                
              </ScrollAnimation>
            </>
          </Row>
        </Container>
        <div
          className="review-carousel"
          onMouseOver={() => setSliderplay(false)}
          onMouseOut={() => setSliderplay(true)}
        >
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeIn">
            <Carousel
              centerMode={mobile ? false : true}
              responsive={responsive}
              showDots={true}
              draggable={true}
              deviceType={props.deviceType}
              autoPlay={sliderplay}
              swipeable={true}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {testimonials.map((review, index) => {
                if (index <= 10) {
                  if (review.comment && review.comment.length < 10) {
                    var long_desc_first_part = review.comment
                  } else {
                    var long_desc_first_part = review.comment
                      ? review.comment.split(/\s+/).slice(0, 10).join(" ") +
                        "..."
                      : ""
                  }

                  return (
                    <>
                      <div className="item">
                        <div className="review-content">
                          <div className="reviewer-block">
                            <div className="reviewer-image">
                              <picture>
                                <source
                                  media="(min-width:992px)"
                                  srcSet={review.reviewer.profilePhotoUrl}
                                />
                                <source
                                  media="(min-width:768px)"
                                  srcSet={review.reviewer.profilePhotoUrl}
                                />
                                <img
                                  src={review.reviewer.profilePhotoUrl}
                                  alt=""
                                />
                              </picture>
                            </div>
                            <div className="reviewer-detail">
                              <span className="name">
                                {review.reviewer.displayName}
                              </span>
                              <span className="review-date">
                                <TimeAgo
                                  date={review.createTime}
                                  formatter={formatter}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="review-feedback">
                            <GetRating starRating={review.starRating} />

                            <p>
                              {review.comment &&
                                review.comment.substring(0, 200)}
                              {review.comment && review.comment.length > 200
                                ? "... "
                                : ""}
                              {review.comment && review.comment.length > 200 && (
                                <a
                                  href="javascript:void(0);"
                                  className="btn-text-link"
                                  onClick={() => handleShow(review.id)}
                                >
                                  More
                                </a>
                              )}
                              {reviewid && reviewid == review.id && (
                                <>
                                  <Modal
                                    show={show}
                                    size="lg"
                                    centered={true}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                    dialogClassName="modal-90w modal-form reviewPopup"
                                  >
                                    <Modal.Header
                                      closeButton
                                      className="contact-close-btn"
                                    >
                                      <Modal.Title className="w-100">
                                        {review.reviewer.displayName}
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <span className="review-date">
                                        <TimeAgo
                                          date={review.createTime}
                                          formatter={formatter}
                                        />
                                      </span>
                                      <GetRating
                                        starRating={review.starRating}
                                      />
                                      <div className="review-cmt-modal">
                                        {review.comment}
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
              })}
            </Carousel>
          </ScrollAnimation>
        </div>
      </section>
    </React.Fragment>
  )
}

export default PropertyManagementGoogleReview

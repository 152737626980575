import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./PropertyManagementBanner.scss"
import PlayVideo from "../../Play/PlayVideo"
import getVideoId from "get-video-id"
import { getSrc } from "gatsby-plugin-image"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "../../utils/animations"
import ReactMarkdown from "react-markdown/with-html"

import { InView } from "react-intersection-observer"

import {
  ScrollingProvider,
  useScrollSections,
  Section,
} from "react-scroll-section"
import { isMobileOnly } from "react-device-detect"
const PropertyManagementBanner = props => {
  const [isPlay, setPlay] = useState(false)
  const videoid = props.VideoURL && getVideoId(props.VideoURL)

  const videoClick = value => {
    // props.handleClickVideo(value);
  }

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          // className="static-banner"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
          <Section id={props.id}>
            <section className={`prop-management-banner ${props.bannerclass}`}>
              {/* {props.VideoURL} */}
              {isMobileOnly && props.Mobile_Banner ? (
                <div className="prop-management-img">
                  <img
                    src={props.Mobile_Banner.url}
                    alt={
                      props.Mobile_Banner?.alternativeText
                        ? props.Mobile_Banner.alternativeText
                        : props.Caption + " -  Location Location"
                    }
                  />
                </div>
              ) : (
                props.BannerImage && (
                  <div className="prop-management-img">
                    <Img
                      fluid={props.BannerImage.url_sharp.childImageSharp.fluid}
                      alt={
                        props.BannerImage.alternativeText
                          ? props.BannerImage.alternativeText
                          : props.Title + " -  Location Location"
                      }
                    />
                  </div>
                )
              )}

              <div className="rockstar-content">
                <Container>
                  {props.bannerContent ? (
                    <ReactMarkdown
                      source={props.bannerContent}
                      escapeHtml={false}
                    />
                  ) : null}
                  <div className="rockstar-button">
                    {props.bannerCta && (
                      <motion.div variants={contentItem}>
                        <div className="btn-groups">
                          <a href="#quiz">{props.bannerCta}</a>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </Container>
              </div>
              <Container>
                <div>
                  {props.VideoURL && (
                    <motion.strong
                      variants={contentItem}
                      href="#"
                      className="video-arrow"
                      onClick={e => {
                        setPlay(true)
                        videoClick("video-open")
                      }}
                    >
                      <i className="icon-video-white"></i>
                    </motion.strong>
                  )}
                </div>
              </Container>
              {/* 
              {props.VideoURL != null && props.VideoURL != "" && (
                <a
                  href="javscript:;"
                  className="video-arrow"
                  onClick={e => {
                    setPlay(true)
                  }}
                >
                  <i className="icon-video-white"></i>
                </a>
              )}
              {props.playtext != null && props.playtext != "" && (
                <a
                  href="javscript:;"
                  onClick={e => {
                    setPlay(true)
                  }}
                >
                  <span className="video-arrow play-text">
                    {props.playtext}
                  </span>
                </a>
              )} */}
              {props.VideoURL != null && videoid && props.VideoURL != "" && (
                <PlayVideo
                  isOpen={isPlay}
                  isCloseFunction={setPlay}
                  videoId={props.VideoURL}
                  isAutoPlay={1}
                />
              )}
            </section>
          </Section>
        </motion.section>
      )}
    </InView>
  )
}
export default PropertyManagementBanner

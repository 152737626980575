import React, { Component, useState, useEffect } from "react"
import { Scrollbars } from "react-custom-scrollbars-2"

import ShadowScrollbars from "./ShadowScrollbars"
import QuizForm from "../../forms/quiz-form"
import { isMobile } from "react-device-detect"

const QuizFormW = props => {
  const [renderComponent, setRenderComponent] = useState(false)

  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, [])

  return (
    <>
      {renderComponent && (
        // <ShadowScrollbars
        //   style={
        //     isMobile ? { width: "", height: 300 } : { width: "", height: 610 }
        //   }
        // >
          <QuizForm />
        // </ShadowScrollbars>
      )}
    </>
  )
}
export default QuizFormW
